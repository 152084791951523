<template>
  <!-- 推荐位 -->
  <div
    v-show="!batchActive"
    ref="recommend-wrapper-container"
    class="recommend-wrapper not-fsp-element cart-recommend-wrapper"
    style="min-height: 1px;"
  >
    <!-- banner位置 -->
    <template v-if="!loginStatus">
      <CartEmptyBannerContainer
        v-show="carts.length == 0"
        :show-banner="showBanner"
        :cart-app-ccc-banner-ssr-data="appCccBannerConfigInfo"
        :cart-banner-ssr-data="cartBannerSsrData"
      />
    </template>
    <ClientOnly>
      <template v-if="showReccomendBlockReload && showAppCcc">
        <RecommendCccNew
          v-show="showReccomend"
          :extend-params="extendParams"
          :show="showCCCX"
        />
      </template>
    </ClientOnly>
  </div>
</template>

<script>
import { computed } from 'vue'
import { debounce } from '@shein/common-function'
import ClientOnly from 'vue-client-only'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

export default {
  name: 'RecommendWrapper',
  setup () {
    const { state } = useStore()
    const carts = computed(() => state.carts)
    const loginStatus = computed(() => state.loginStatus)
    const batchActive = computed(() => state.batchActive)
    const cartAbtInfo = computed(() => state.cartAbtInfo)
    const appCccBannerConfigInfo = computed(() => state.appCccBannerConfigInfo)
    const cartBannerSsrData = computed(() => state.cartBannerSsrData)
    return {
      carts,
      loginStatus,
      batchActive,
      cartAbtInfo,
      appCccBannerConfigInfo,
      cartBannerSsrData
    }
  },
  components: {
    ClientOnly,
    CartEmptyBannerContainer: () => import(/* webpackChunkName: "cartv2-recommend-new" */'public/src/pages/cart_v2/components/cartEmptyBanner/index.vue'),
    RecommendCccNew: () => import(/* webpackChunkName: "cartv2-recommend-new" */'./recommend-ccc.vue'),
  },
  props: {
    shouldReload: {
      type: Boolean,
      default: false
    },
    needRender: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showReccomendBlockReload: false, // 是否展示推荐位模块
      recommendObserver: null,
      showReccomend: 1, // 0: 不展示 1: 展示(初始态) 2: 展示(已展示)
      showCCCX: false // 是否展示cccx推荐
    }
  },
  computed: {
    // 推荐位需要过滤的商品id
    filterData () {
      const goodsIds = []
      const cateIds = []
      if (this.carts && this.carts.length) {
        this.carts.forEach(item => {
          if (item.product) {
            goodsIds.push(item.product.goods_id)
            cateIds.push(item.product.cat_id)
          }
        })
      }
      return {
        goodsIds,
        cateIds
      }
    },
    extendParams() {
      return {
        cate_ids: this.filterData.cateIds.join(','),
        goods_ids: this.filterData.goodsIds.join(',')
      }
    },
    showBanner () {
      return this.cartAbtInfo.BannerDistrictimage?.param?.BannerDistrictShow === 'Show'
    },
    showAppCcc() {
      return this.cartAbtInfo.componentswitch?.param?.shopbagrecstyle == '1'
    }
  },
  watch: {
    shouldReload (val) {
      if (val) {
        this.handleCCCRecReload()
      }
    },
    carts (n, o) {
      this.handleRecommendInit(n, o)
    }
  },
  mounted () {
    if(this.carts?.length === 0 || this.needRender) {
      this.handleCCCRecReload()
    }
    this.initObserver()
    window.requestIdleCallback(() => {
      this.showCCCX = true
    }, { timeout: 1000 })
  },
  activated () {
    this.initObserver()
  },
  beforeDestroy () {
    this.destroyObserver()
  },
  deactivated () {
    this.destroyObserver()
  },
  methods: {
    destroyObserver(){
      if(this.recommendObserver) {
        this.recommendObserver.disconnect()
        this.recommendObserver = null
      }
    },
    initObserver(){
      if(this.recommendObserver) return
      let ele = this.$refs['recommend-wrapper-container']
      this.recommendObserver = new IntersectionObserver((entries)=>{
        entries.forEach(entry => {
          const { intersectionRatio = 0 } = entry
          if (intersectionRatio > 0 && this.showReccomend != 2) {
            this.showReccomend = 2
            this.showCCCX = true
            // this.recommendObserver.unobserve(ele) // 可视范围内渲染过了推荐组件则不在观察
            // this.recommendObserver = null
          } else if (intersectionRatio <= 0) {
            // 如果存在b类型的低效用户引导提示，则推荐位始终展示
            const recommendGuideBDom = document.querySelector('#j-recommend-guide__b')
            if (this.showReccomend == 2 && recommendGuideBDom == null) {
              this.showReccomend = 0
            }
          }
        })
      })
      
      this.recommendObserver.observe(ele)
    },
    toRender() {
      if(this.showReccomendBlockReload) return
      this.handleCCCRecReload()
    },
    handleRecommendInit (n, o) {
      const emptyToHaveItems = n && o && o.length === 0 && n.length > 0 // 空 => 非空
      const haveItemsToEmpty = n && o && n.length === 0 && o.length > 0 // 非空 => 空
      // 空 -> 有 || 有 -> 空
      // 购物车空与非空切换临界值，需要对推荐进行重载
      if (emptyToHaveItems || haveItemsToEmpty) {
        this.handleCCCRecReload(true)
      }
    },
    changeReload (flag) {
      if (flag) {
        this.handleCCCRecReload()
      }
    },
    handleCCCRecReload: debounce({ func: function (toTop = false) {
      this.showReccomendBlockReload = false
      this.$nextTick(() => {
        // 购物车状态切换回到购物车顶部
        toTop && window.scrollTo({ top: 0, behavior: 'smooth' })
        this.showReccomendBlockReload = true
        this.$emit('reload-success')
      })
    }, wait: 300 }),
  }
}
</script>
<style lang="less">
.cart-recommend-wrapper{
  .static-image__wrapper{
    margin: 0!important;/* stylelint-disable-line declaration-no-important */
  }
  .base-img{
    border-radius: 0!important;/* stylelint-disable-line declaration-no-important */
  }
}
</style>
